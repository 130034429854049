import React from 'react'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '../../../../features/ui/action'
import { useDispatch } from 'react-redux'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { StyledLinkMegaMenu, StyledListItem } from './CmsAnchorMegaMenu.style'

const CmsAnchorMegaMenu: React.FC<{
  to: string
  children: React.ReactNode
  elementId?: string
}> = ({ to, children, elementId }) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()

  const link = `${basePath}${to}`

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }

  return (
    <StyledListItem>
      <StyledLinkMegaMenu data-element-id={elementId || 'X_X_'} href={link}>
        <span onClick={closeMegaMenu}>{children}</span>
      </StyledLinkMegaMenu>
    </StyledListItem>
  )
}

export default CmsAnchorMegaMenu
